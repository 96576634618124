import React, { Component } from "react";
import {routesCCV, routesMVV, routesIA, routesSVF} from './routes';
import {
  Route,
  Switch,
  BrowserRouter as Router
} from "react-router-dom";
import decode from 'jwt-decode';

//Import Login Page
import Login from '../src/pages/Login/Index6';

// Import Scss
import "./theme.scss";

//Import Icon Css
import "./assets/css/materialdesignicons.min.css";


const isAuthenticated = () => {
  const token = localStorage.getItem('token')
  let isValid = true
  try {
      isValid = decode(token);
  } catch (e) {
      return false;
  }
  return isValid;

};

const MyRoute = (props) => (
  isAuthenticated() && isAuthenticated().role === 'ccv' ?
    routesCCV.map((route, idx) => (
      <Route exact path={route.path} component={route.component} key={idx} />
    )) 
  : isAuthenticated() && isAuthenticated().role === 'mvv' ?
    routesMVV.map((route, idx) => (
      <Route exact path={route.path} component={route.component} key={idx} />
    ))
  : isAuthenticated() && isAuthenticated().role === 'ia' ?
    routesIA.map((route, idx) => (
      <Route exact path={route.path} component={route.component} key={idx} />
    ))
  : isAuthenticated() && isAuthenticated().role === 'svf' ?
    routesSVF.map((route, idx) => (
      <Route exact path={route.path} component={route.component} key={idx} />
    ))
    : <Route exact component={Login} key={5} />
)

class App extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }
    render() {
        return (
          <React.Fragment>
          <Router>
            <Switch>
              <MyRoute />
            </Switch>
          </Router>
        </React.Fragment>
      );
    }
  }
  
  export default App;