// import NotFound from "./pages/404/404";
import Login from "./pages/Login/Index6";
import Ccv from "./pages/Ccv/Index1";
import Mvv from "./pages/Mvv/Index1";
import Ia from "./pages/Ia/Index1";
import Svf from "./pages/Svf/Index1";
import CP from "./pages/ChangePassword/Index6";


const routesCCV = [
  { path: "/ccv", component: Ccv },
  { path: "/login", component: Login },
  { path: "/changePassword", component: CP },
  { path: "/", component: Login },
  // { component: NotFound },
]
const routesMVV = [
  { path: "/mvv", component: Mvv },
  { path: "/login", component: Login },
  { path: "/changePassword", component: CP },
  { path: "/", component: Login },
  // { path: "", component: NotFound },
]
const routesIA = [
  { path: "/ia", component: Ia },
  { path: "/login", component: Login },
  { path: "/changePassword", component: CP },
  { path: "/",  component: Login  },
  // { component: NotFound },
]
const routesSVF = [
  { path: "/svf", component: Svf },
  { path: "/login", component: Login },
  { path: "/changePassword", component: CP },
  { path: "/", component: Login },
  // { component: NotFound },
]


export {
  routesCCV,
  routesMVV,
  routesIA,
  routesSVF,
};

