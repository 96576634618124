import React, { Component, Suspense } from 'react';

const NavbarPage = React.lazy(() => import('../../components/Navbar/Navbar_PageCcv'));
const Section = React.lazy(() => import('./section'));
const Nosotros = React.lazy(() => import('../../components/About/Ccv/About'));
const SGSST = React.lazy(() => import('../../components/SGSST/Ccv/SGSST'));
const Solicitudes = React.lazy(() => import('../../components/Solicitudes/Ccv/Solicitudes'));
const Organigrama = React.lazy(() => import('../../components/Organigrama/Ccv/Organigrama'));
const Formatos = React.lazy(() => import('../../components/Formatos/Ccv/Formatos'));
const Footer = React.lazy(() => import('../../components/Footer/login-Footer'));


class Index1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navItems : [
                { id: 1 , idnm : "home", navheading: "Inicio" },
                { id: 2 , idnm : "nosotros", navheading: "Nosotros" },
                { id: 3 , idnm : "sgsst", navheading: "SGSST" },
                { id: 5 , idnm : "solicitudes", navheading: "Solicitudes" },
                { id: 6 , idnm : "formatos", navheading: "Formatos" },
            ],
            pos : document.documentElement.scrollTop,
            imglight : true,
            navClass : ""
        };
    }

    componentDidMount() {
        window.addEventListener("scroll", this.scrollNavigation, true);
    }

    componentWillUnmount(){
        window.removeEventListener("scroll", this.scrollNavigation, true);
    }

    scrollNavigation = () => {
        var scrollup=document.documentElement.scrollTop;
        if(scrollup > this.state.pos)
        {
            this.setState({navClass : "nav-sticky", imglight : false});
        }
        else
        {
            this.setState({navClass : "", imglight : true});
        }
    };

    Loader = () => {
        return (
            <div id="preloader">
                <div id="status">
                    <div className="spinner">Loading...</div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <Suspense fallback = {this.Loader()} >

                    {/* Importing Navbar */}
                    <NavbarPage navItems={this.state.navItems} navClass={this.state.navClass} imglight={this.state.imglight} />

                    {/* Importing section */}
                    <Section/>

                    {/* Importing Nosotros */}
                    <Nosotros/>

                    {/* Importing Organigrama */}
                    <Organigrama/>

                    {/* Importing SGSST */}
                    <SGSST/>

                    {/* Importing Solicitudes */}
                    <Solicitudes/>

                    {/* Importing Formatos */}
                    <Formatos/>
                    
                    {/* Importing Footer */}
                    <Footer/>
                </Suspense>

            </React.Fragment>
        );
    }
}

export default Index1;