import React, { Component, Suspense } from 'react';

const Section = React.lazy(() => import('./section'));
const Footer = React.lazy(() => import('../../components/Footer/login-Footer'));

class Index6 extends Component {

    Loader = () => {
        return (
            <div id="preloader">
                <div id="status">
                    <div className="spinner">Loading...</div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <Suspense fallback = {this.Loader()} >

                    {/* Importing section */}
                    <Section/>
                    
                    {/* Importing footer */}
                    <Footer/>
                </Suspense>

            </React.Fragment>
        );
    }
}

export default Index6;